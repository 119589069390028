// App.js
import React, { useState, useEffect } from 'react';
import Template1 from './template1';
import Template2 from './template2';
import Template0 from './galleryprofile';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
const Templaterenderer = () => {
    const [data, setData] = useState(null);
    const [template, setTemplate] = useState(null);
    const { id } = useParams();
    const [lang,countrycode,BID256] = id.split('_');
  
  const slidersettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(countrycode=='IN'){
        const response=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getbusinesswebprofiledata?parameter=${BID256}`)
     const result = await response.json();
        setData(result.busdata); // Assuming result.data contains the array of image URLs
        setTemplate(result.busdata.section1.Template)
        /*const manifest = await import('./manifest.json');
       
      manifest.name = result.busdata.busname;
      manifest.icons=[
        {
          "src": result.busdata.buslogo,
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": result.busdata.buslogo,
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": result.busdata.buslogo,
          "type": "image/png",
          "sizes": "512x512"
        }
      ]*/
       }
        if(countrycode=='EE'){
          const response=await fetch(`https://us-central1-jiffybook-app.cloudfunctions.net/getbusinesswebprofiledata?parameter=${BID256}`)
     const result = await response.json();
        setData(result.busdata); // Assuming result.data contains the array of image URLs
        setTemplate(result.busdata.section1.Template)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    }, []);
  
    if (!data) {
      return <div>Loading...</div>;
    }
    switch (template) {
        case 'A':
          return <Template1 data={data} />;
        case 'B':
          return <Template2 data={data} />;
        default:
          return  <Template0 data={data} />;
      }

};

export default Templaterenderer;
